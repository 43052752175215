<template>
    <div class="main">
        Mentions légales
    </div>
</template>

<script>
export default {
    name : 'LegalNotices'
}
</script>